.corTextoDados {
  color: #112c50 !important;
}
.fontBotao2 {
  background: #112c50 !important;
  font-family: 'Poppins' !important;
}
.botaoCancelarEdicao {
  background-color: #fff !important;
  color: #96b7c7 !important;
  border-color: #96b7c7 !important;
  border: 1px solid !important;
}

.alinhamento {
  margin-left: 1.5rem !important;
}

.alinhamentoEdicaoDados {
  margin-left: 1.5rem !important;
}

.container-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 960px) {
  .layout-content-wrapper .layout-content > div {
    padding: 0 0.5rem;
  }
}
