.active-menuitem:hover {
  color: #53c3c2 !important;
  /*background-color: #071b36!important;*/
  border: 2px solid rgba(83, 195, 194, 0.42);
}

.p-ripple:hover {
  background-color: #071b36 !important;
}

.layout-menu ul a.active-route {
  background-color: #071b36 !important;
}
.card {
  position: relative !important;
}

p,
label {
  font-size: 1rem !important;
  color: #000;
}

h2 {
  font-weight: normal !important;
  font-size: 25px !important;
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: rgba(17, 44, 80, 0.5);
  top: 50px;
  bottom: 0;
  left: 7px;
}

/* Container around content */
.container {
  position: relative;
  width: 100%;
}

/* The circles on the timeline */
.container::after {
  content: '';
  position: absolute;
  width: 17px;
  height: 16px;
  background-color: #112c50;
  top: 50px;
  border-radius: 50%;
  z-index: 1;
}

.container.active::after {
  background-color: #53c3c2;
}

.container.checked::after {
  background-color: #96b7c7;
}

.p-dropdown-panel.p-component.p-connected-overlay-enter-done {
  max-width: 100% !important;
}

.p-dropdown-trigger {
  background: transparent !important;
}

/* Place the container to the left */
/* .left {
  left: 0;
} */

/* The actual content */
.content {
  padding: 50px 0 0 30px;
  position: relative;
}

.content h3 {
  /* font-size: 14px !important; */
  color: #112c50 !important;
}

.content.active h3 {
  color: #53c3c2 !important;
}

.content.checked h3 {
  color: #96b7c7 !important;
}
.layout-menu-container {
  padding: 0rem !important;
}

.p-button.p-component {
  text-align: center !important;
}

.p-dropdown-label.p-inputtext.p-placeholder {
  z-index: 10 !important;
}

.p-dropdown-item {
  z-index: 100000000 !important;
}

.p-multiselect-item div {
  width: 1.4rem !important;
}

.p-multiselect-item li div {
  display: none;
}

.layout-topbar {
  position: fixed;
  width: 100%;
  z-index: 200;
}

.layout-content {
  padding: 6rem 2rem 2rem 2rem;
}

#submenu-item {
  width: fit-content !important;
}

@media (max-width: 992px) {
  .mobile-logo {
    width: 84px !important;
    height: 40px !important;
  }
  .perfil-menu {
    position: relative;
    /* padding-bottom: 2rem;
    margin: 0 1rem; */
  }
  .perfil-menu ul {
    list-style: none;
  }
  .perfil-menu ul li {
    text-align: center;
    margin: 1rem 0;
  }
  .perfil-menu ul span li {
    text-align: left !important;
  }
  .perfil-menu ul li span {
    color: #53c3c2;
    background: #fff;
    font-size: 24px;
    font-weight: 500;
    padding: 1px 10px;
    border-radius: 50%;
  }
  .perfil-menu ul li a {
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .perfil-menu ul li button {
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .perfil-menu ul li i {
    margin-right: 0.5rem;
  }
  .perfil-menu ul li img {
    width: 35px;
    border-radius: 50%;
    margin: auto !important;
  }
  .profile-menu {
    padding: 0 !important;
  }
  .logo {
    display: none !important;
  }
  .layout-sidebar {
    margin-top: 71px !important;
    padding-bottom: 74px !important;
    border-top: 1px solid rgba(83, 195, 194, 0.42);
  }
  .layout-content {
    padding: 2rem;
  }
  .teste-gratis button {
    background: #96b7c7;
    width: 100%;
    text-align: center;
    padding: 0.5rem 1rem;
  }
  .pi.pi-chevron-left {
    color: #53c3c2 !important;
  }
}

@media screen and (min-width: 992px) {
  .layout-wrapper.layout-slim .layout-sidebar {
    width: 6.8rem !important;
  }
  /* .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > ul {
    left: 6.5rem !important;
    top: 110px !important;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem {
    position: inherit !important;
  } */
  .perfil-menu {
    display: none !important;
  }
  .perfil-menu-span li i {
    display: none;
  }
  .notificacao-mobile {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .layout-wrapper.layout-slim .topbar-left .menu-button {
    display: none;
  }
  .layout-wrapper.layout-slim .topbar-left .topbar-separator {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-sidebar {
    width: 6rem;
    overflow: visible;
    z-index: 999;
  }
  .layout-wrapper.layout-slim .layout-sidebar .logo-image {
    margin-right: 0;
  }
  .layout-wrapper.layout-slim .layout-sidebar .app-name {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-sidebar .layout-menu-container {
    overflow: visible;
  }
  .layout-wrapper.layout-slim .layout-content-wrapper {
    margin-left: 0 !important;
  }
  .layout-wrapper.layout-slim .layout-content-wrapper .layout-content {
    margin-left: 6rem !important;
  }
  .layout-wrapper.layout-slim .layout-menu ul {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu li.active-menuitem > ul {
    display: block;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem {
    position: relative;
    border-radius: 4px;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem {
    padding: 0 !important;
  }
  .layout-wrapper.layout-slim
    .layout-menu
    .layout-root-menuitem
    > .layout-menuitem-root-text {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > span {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > a,
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > .p-link {
    display: block;
    text-align: center;
    border-radius: 4px;
    padding: 0.5rem 0;
    cursor: pointer;
    outline: none;
    transition: background-color 0.2s;
    width: 100%;
  }
  .layout-wrapper.layout-slim
    .layout-menu
    .layout-root-menuitem
    > a
    .layout-submenu-toggler,
  .layout-wrapper.layout-slim
    .layout-menu
    .layout-root-menuitem
    > .p-link
    .layout-submenu-toggler {
    display: none;
  }
  .layout-wrapper.layout-slim
    .layout-menu
    .layout-root-menuitem
    > a
    .layout-menuitem-icon,
  .layout-wrapper.layout-slim
    .layout-menu
    .layout-root-menuitem
    > .p-link
    .layout-menuitem-icon {
    font-size: 1.25rem;
    color: #fff !important;
  }
  .layout-wrapper.layout-slim
    .layout-menu
    .layout-root-menuitem
    > a
    .layout-menuitem-text,
  .layout-wrapper.layout-slim
    .layout-menu
    .layout-root-menuitem
    > .p-link
    .layout-menuitem-text {
    font-size: 0.875rem;
    display: block;
    margin-top: 0.25rem;
    color: #fff !important;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > ul {
    position: absolute;
    /*position: fixed;*/
    left: 5rem;
    top: 0;
    /*top: 170px;*/
    min-width: 15rem;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 1rem;
  }
  .layout-wrapper.layout-slim
    .layout-menu
    .layout-root-menuitem
    > ul.layout-menu-enter {
    opacity: 0;
    transform: translateX(20px);
  }
  .layout-wrapper.layout-slim
    .layout-menu
    .layout-root-menuitem
    > ul.layout-menu-enter-active {
    opacity: 1;
    transform: none;
    transition: opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1),
      transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .layout-wrapper.layout-slim
    .layout-menu
    .layout-root-menuitem
    > ul.layout-menu-exit {
    opacity: 1;
    transform: none;
  }
  .layout-wrapper.layout-slim
    .layout-menu
    .layout-root-menuitem
    > ul.layout-menu-exit-active {
    opacity: 0;
    transform: translateX(20px);
    transition: opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1),
      transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }
}
