.status-ativo,
.status-cancelado,
.status-pago,
.status-expirado
.status-reembolsado,
.status-suspenso,
.status-contestado,
.status-protesto,
.status-pendente,
.status-pago2 {
  width: 8rem;
  height: 2rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: #fff;
  font-size: 1rem;
}

.status-pago2 {
  margin-top: 1rem;
}

.status-pago,
.status-pago2,
.status-pendente {
  background-color: #4d4fa1;
}

.status-ativo {
  background-color: #53c3c2;
}

.status-cancelado {
  background-color: #ea736a;
}

.status-expirado {
  background-color: #96b7c7;
}

.status-reembolsado {
  background-color: #56e07d;
}

.status-suspenso {
  background-color: #eaa06a;
}

.status-contestado {
  background-color: #7a57c2;
}

.status-protesto {
  background-color: #335893;
}

.corTextoTitulo {
  color: #112c50 !important;
  font-size: 14px;
}
