.buttomDownload {
  width: 130px !important;
}
.buttomDownload2 {
  width: 180px !important;
}
.alinhamento {
  margin-top: -76px;
  margin-right: 140px;
}
.corUl {
  color: #000;
}
