.subtituloHistorico {
  font-size: 16px !important;
}
.botaoFechar {
  background-color: #fff !important;
  color: #96b7c7 !important;
  border-color: #96b7c7 !important;
  border: 1px solid !important;
}
.tituloModal {
  color: #112c50 !important;
}
.datatable-style-demo .status-falha {
  background: #ea736a;
  border: 1px solid #ea736a !important;
  box-sizing: border-box;
  border-radius: 3px;
  color: #fff;
  min-width: 8rem;
  justify-content: center;
}

.datatable-style-demo .status-processada {
  color: #fff;
  background: #53c3c2;
  border: 1px solid #53c3c2 !important;
  box-sizing: border-box;
  border-radius: 3px;
  min-width: 8rem;
  justify-content: center;
}

.datatable-style-demo .status-darf {
  background: #112c50;
  border: 1px solid #112c50 !important;
  box-sizing: border-box;
  border-radius: 3px;
  color: #fff;
  padding: 0.25em 0.5rem;
  letter-spacing: 0px;
  min-width: 7rem;
  justify-content: center;
  /*position: absolute;*/
  /*top: 285px;*/
}

.datatable-style-demo .status-pendente {
  background: #4d4fa1;
  border: 1px solid #4d4fa1 !important;
  box-sizing: border-box;
  border-radius: 3px;
  color: #fff;
  padding: 0.25em 1.2rem;
  min-width: 7rem;
  justify-content: center;
}

.p-splitbutton-defaultbutton {
  display: none !important;
}
.p-splitbutton-menubutton {
  top: 9px !important;
}

/*.p-menu {
  top: 510px !important;
}*/

.p-button-danger2 {
  display: block !important;
  background: #ffffff !important;
  top: -4px !important;
}

.p-splitbutton-menubutton {
  color: #000 !important;
}

/*.p-fileupload-choose {
  background: #53c3c2 !important;
  float: right;
}*/
hr {
  /*background: rgb(202 5 30) !important;*/
  background: rgb(83 195 194) !important;
}

.bovJtr div {
  padding: 2.5 rem 0.1rem !important;
}
.testeCblc {
  margin-left: 0px !important;
  margin-right: 1px !important;
  background-color: rgba(150, 183, 199, 0.1) !important;
}
.juEWnm {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.div1Bmf {
  border: 0px solid rgba(83, 195, 194, 0.4) !important;
  background-color: #fff !important;
}
.div2Bmf {
  border-top: 0px solid rgba(83, 195, 194, 0.4) !important;
}
.p-datatable .p-datatable-header {
  background: #fff !important;
}
.marginRodape {
  margin-bottom: -14px;
}
.labelRodapeBmf {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  color: #112c50;
}
.labelRodapeBmf2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  color: #112c50;
  margin-left: -20px;
  margin-right: 10px;
}
.labelRodapeBmf3 {
  margin-left: -50px;
  margin-right: 40px;
}

.p-datatable {
  border: 2px solid rgba(83, 195, 194, 0.42);
}

.cabecalhoHistoricoModal {
  color: #112c50 !important;
}
.cabecalhoHistorico {
  color: rgba(17, 44, 80, 1) !important;
}

@media screen and (max-width: 1280px) {
  .subtituloModal {
    margin-left: 16px;
  }
}

@media screen and (max-width: 1380px) {
  .p-sortable-column-icon {
    font-size: 0.8rem;
    position: absolute;
    top: 18px;
  }
  .cabecalhoHistorico {
    font-size: 13px !important;
    background: rgba(150, 183, 199, 0.1) !important;
  }

  .sc-ksdxgE {
    margin-right: -28px !important;
    margin-left: -16px !important;
  }
}

@media screen and (min-width: 1920px) {
  .p-sortable-column-icon {
    font-size: 0.8rem;
    top: 18px;
  }
  .cabecalhoHistorico {
    font-size: 13px !important;
  }
  .sc-ksdxgE {
    margin-right: -28px !important;
    margin-left: -16px !important;
  }
  .margingDiv1Filtro {
    margin-right: -240px !important;
  }
  .div1Modal {
    width: 100% !important;
  }
}

@media screen and (min-width: 1380px) {
  .margingDiv1Filtro {
    margin-right: -70px;
  }
  .subtituloModal {
    margin-left: 16px;
  }
  .cabecalhoHistoricoModal {
    font-size: 13px !important;
    color: #112c50 !important;
  }
}

@media screen and (min-width: 1440px) {
  .margingDiv1Filtro {
    margin-right: -90px;
  }

  .subtituloModal {
    margin-left: 2px;
  }

  .div1Modal {
    width: 1400px;
  }
}

.titulo {
  color: #000000 !important;
}

.borderTabelaLista {
  border: 2px solid rgba(83, 195, 194, 0.42) !important;
  box-sizing: 'border-box' !important;
  padding: 10px;
  border-radius: 5px;
}

.pi-ellipsis-v {
  color: #000 !important;
}
