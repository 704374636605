@media screen and (min-width: 1920px) {
  .resumoFinanceiro {
    padding-left: 600px !important;
    font-weight: bold !important;
  }
}

@media screen and (min-width: 1440px) {
  .resumoFinanceiro {
    padding-left: 380px;
    font-weight: bold !important;
  }
}

@media screen and (max-width: 1285px) {
  .resumoFinanceiro {
    padding-left: 300px;
    font-weight: bold !important;
  }
}

.resumoNegociosTitulo {
  font-weight: bold !important;
}

.subTituloBov {
  font-size: 16px !important;
}

#espectitulo input {
  border-color: rgba(17, 44, 80, 0.5) !important;
}

#espectitulo::placeholder {
  color: rgba(17, 44, 80, 0.5) !important;
}

#espectitulo.invalid input {
  border-color: red !important;
}

#espectitulo.invalid input::placeholder {
  color: red !important;
}
