.p-dropdown-trigger,
.p-multiselect-trigger {
  background: #fff !important;
  color: #112c50 !important;
}

.card .p-button-outlined.p-button-info {
  color: #a8c0ce !important;
  border: 1px solid #a8c0ce !important;
}

.tamanhoTitulo {
  font-size: 25px !important;
  color: #000 !important;
}

.colorIcon {
  color: #53c3c2 !important;
}

.subTitulo {
  margin-top: 50px !important;
  color: #000 !important;
  margin-bottom: 63px !important;
}

.subTitulosDentroModal {
  color: #112c50 !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: rgba(17, 44, 80, 0.5) !important;
  font-family: Poppins !important;
}

.tituloColunasLista {
  color: #112c50 !important;
}

.dataTable {
  border: 1px solid rgba(83, 195, 194, 0.42) !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
}

.p-datatable .p-datatable-tbody > tr {
  border-bottom: 1.1px solid rgba(83, 195, 194, 0.42);
}

.p-inputtext {
  font-family: Poppins !important;
}

.cardLinhaVerde {
  border: 1px solid rgba(83, 195, 194, 0.42) !important;
}

.erroPreenchimento {
  color: #ea736a !important;
}

.erroDropdown {
  border-color: #ea736a !important;
}

.erroInputValorLiquido {
  border-color: #ea736a !important;
}

.p-inputnumber.p-invalid.p-component > .p-inputtext {
  border-color: #ea736a !important;
}

.tabelaDados {
  width: 103% !important;
}

.fontBotaoCancelar {
  background: transparent !important;
  color: #a8c0ce !important;
  border: 1px solid #a8c0ce !important;
  font-family: 'Poppins' !important;
}

.subtituloProventos {
  font-size: 16px !important;
  margin-top: 30px;
  margin-bottom: 50px;
}

.tituloProventos {
  font-size: 25px !important;
  padding-bottom: 2rem;
  margin-top: 0px;
  line-height: normal !important;
}

.p-field {
  width: 100%;
}

@media screen and (max-width: 1380px) {
  .p-sortable-column-icon {
    font-size: 0.8rem;
    position: inherit !important;
    top: 18px;
  }
}

@media screen and (min-width: 1280px) {
  .botoesAcoes {
    margin-left: 70px;
  }
}

@media screen and (max-width: 768px) {
  .modalContainer .p-formgrid:nth-child(2),
  .modalContainer .p-formgrid:nth-child(3) {
    flex-direction: column;
  }
}
