.iconTitulo {
  background: transparent !important;
}

.subtituloBensDireitos {
  font-size: 16px !important;
  margin-top: -30px;
  margin-bottom: 50px;
}

.tituloBensDireitos {
  font-size: 25px !important;
  padding-bottom: 2rem;
  margin-top: 0px;
  line-height: normal !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  border: none !important;
}

.p-datatable .p-datatable-tbody > tr {
  border-bottom: 0px solid rgba(83, 195, 194, 0.42);
}
